
.outlined-sheet {
    padding-top: 30px;
    padding-bottom: 30px;
}
@media (min-width: 768px) {
    .outlined-sheet {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
